@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'SF Pro';
    font-weight: normal;
    font-style: normal;
    src: url('./assets/font/SF-Pro-Text-Regular.ttf') format('ttf'),
  }


  @font-face {
    font-family: "Nunito";
    src: url("../public/font/Nunito/Nunito-VariableFont_wght.ttf") format("ttf");
  }

  
  @font-face {
    font-family: "Inter";
    src: url("../public/font/Inter/Inter-VariableFont_slnt\,wght.ttf") format("ttf");
  }